<script lang="ts" setup>
import {
  ComponentBlockTextCta,
  ComponentBlockTextCtaFragment,
  Enum_Componentblocktextcta_Variation,
} from "~~/types/graphql";
import Arrow from "@/assets/icons/arrow.svg?component";

const props = defineProps<{
  TextCtaHeadline: ComponentBlockTextCtaFragment["TextCtaHeadline"];
  TextCtaItem: ComponentBlockTextCtaFragment["TextCtaItem"];
  TextCtaLink: ComponentBlockTextCtaFragment["TextCtaLink"];
  TextCtaVariation: Enum_Componentblocktextcta_Variation;
}>();
const shownHoverTextIndex = ref(0);

function pad(value: string | number) {
  return value.toString().padStart(2, "0");
}

function handleTextHover(index: number) {
  shownHoverTextIndex.value = index;
}
function handleTextHoverLeave() {
  shownHoverTextIndex.value = 0;
}
</script>
<template>
  <layout-block
    :title="props.TextCtaHeadline"
    :link="props.TextCtaLink?.data?.attributes?.Slug"
    :class="{
      'bg-integer-black text-integer-white':
        TextCtaVariation === Enum_Componentblocktextcta_Variation.Dark,
      'bg-integer-white text-integer-black':
        TextCtaVariation === Enum_Componentblocktextcta_Variation.Light,
    }"
    class="text-cta"
  >
    <div class="sm:grid grid-cols-12">
      <div
        class="relative col-start-1 sm:col-end-7 md:col-end-6 max-w-prose self-end mt-10 hidden sm:block"
      >
        <transition-group name="slide-up">
          <p
            class="absolute bottom-0 text-default"
            v-for="(item, index) in props.TextCtaItem"
            v-show="index === shownHoverTextIndex"
            :key="index"
          >
            {{ item?.text }}
          </p>
        </transition-group>
      </div>
      <div class="links col-start-8 col-end-13 grid mt-4 sm:mt-0">
        <nuxt-link
          v-for="(item, index) in props.TextCtaItem"
          :to="item?.page?.data?.attributes?.Slug"
          :class="[
            {
              'text-gray hover:text-integer-white':
                props.TextCtaVariation ===
                Enum_Componentblocktextcta_Variation.Dark,
              'hover:text-gray':
                props.TextCtaVariation ===
                Enum_Componentblocktextcta_Variation.Light,
            },
          ]"
          class="link flex items-center leading-1 group transition border-b border-gray py-8 gap-5.2"
          @mouseover="handleTextHover(index)"
          @mouseleave="handleTextHoverLeave()"
        >
          <span class="self-start">
            {{ pad(index + 1) }}
          </span>
          <span
            class="text-h2 break-all group-hover:translate-x-2 transition"
            >{{ item?.page?.data?.attributes?.Name }}</span
          >
          <base-button
            :type="
              TextCtaVariation === Enum_Componentblocktextcta_Variation.Dark
                ? 'primary'
                : 'secondary'
            "
            :title="item?.page?.data?.attributes?.Name"
            transparent
            class="ml-auto right-2 transition"
            ><template #icon-right><Arrow /></template
          ></base-button>
        </nuxt-link>
      </div>
    </div>
  </layout-block>
</template>
<style scoped>
.transition {
  transition: all 0.5s ease-out;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
